.best {
    width: 100%;
    margin: 4rem 0;
    text-align: center;
}

.best > div {
    display: flex;
    justify-content: center;
}

.best > div p {
    margin: 1rem 2rem;
    font-size: 1.2rem;
}

div p:hover{
    color: #0099FF;
}

.bold {
    font-weight: 700;
}

.best .container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1.2rem;
    padding: 1rem;
}

.best img {
    width: 100%;
}

@media screen and (max-width:940px) {
    .best {
        margin: 1rem 0;
    }

    .best > div p {
        margin: 1rem;
        font-size: 1rem;
    }

    .best .container {
        grid-template-columns: 1fr;
        grid-gap: .5rem;
    }
}

@media screen and (max-width: 478px) {
    .best > div p {
        margin: 1rem .5rem;
    }
}




