@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;600;700&display=swap');

*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
ul{
  list-style: none;
}
a{
  text-decoration: none;
  color: #333;
}

.container {
  max-width: 1240px;
  margin: auto;
}

.btn{
  color: #fff;
  padding: 12px 24px;
  background-image: linear-gradient(45deg, #55adc8 0%, #15c6fd 100%);
  border-radius: 8px;
  border: none;
  font-size: 1rem;
  transition: 0.4s;
  cursor: pointer;
}

.btn:active{
  transform: translate(4px);
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.3);
  border-bottom: none;
}

body {
  margin: 0;
  font-family: 'Raleway', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Raleway', 'Courier New',
    monospace;
}
