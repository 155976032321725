
.form-section{
    text-align: center;
  background-color: aliceblue;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  margin-bottom: 25px;
}

.Form {
  width: 500px;
  height: 600px;
  background-color: #15c6fd;
  box-shadow: 3px 3px 10px;
  /* font-family: Arial, Helvetica, sans-serif; */
  display: flex;
  flex-direction: column;
}

.Form .title {
  color: white;
  flex: 20%;
  width: 100%;
  display: grid;
  place-items: center;
  font-size: 35px;
}

.Form .inputs {
  flex: 80%;
  width: 100%;
}

.inputs input {
  width: 350px;
  height: 40px;
  font-size: 20px;
  border-radius: 6px;
  border: none;
  padding-left: 10px;
  margin-top: 10px;
}

.inputs #submit {
  cursor: pointer;
}

.inputs #submit:hover{
  background-color: #5d8ac8;
  color: #fff;
}
.errors{
  color: #d44136;
}